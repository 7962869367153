import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyBjR1F1fVZyi6aNPBQNczjtAT2trv1xu8w',
  authDomain: 'codex-jacques.firebaseapp.com',
  projectId: 'codex-jacques',
  storageBucket: 'codex-jacques.appspot.com',
  messagingSenderId: '1037046041759',
  appId: '1:1037046041759:web:39e6e6d8aa5e29b24ea6aa',
  measurementId: 'G-65GGKE8N03',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
